import CloseIcon from "@material-design-icons/svg/filled/close.svg";
import classNames from "classnames";
import * as React from "react";

import { Button } from "../Button";

export const ModalCloseButton: React.FC<{
  onClose: () => void;
  classname?: string;
}> = ({ onClose, classname }) => {
  return (
    <div className={classNames("flex justify-end mb-[8px]", classname)}>
      <Button
        className="rounded-[4px]"
        variant="wrapper"
        action={onClose}
        aria-label="Close modal"
      >
        <CloseIcon
          className="text-navy-900 fill-current w-[24px] h-[24px] flex-shrink-0"
          aria-hidden
        />
      </Button>
    </div>
  );
};
